import React, { FC, useEffect, useState } from "react";

interface MessageBirdButtonProps {
  className?: string;
  text: string;
}

export const MessageBirdButton: FC<MessageBirdButtonProps> = ({
  className,
  text,
}) => {
  const [isChatVisible, setIsChatVisible] = useState(false);

  // Function to toggle the visibility of the chat widget
  const toggleChat = () => {
    setIsChatVisible((prev) => !prev);

    //@ts-ignore
    console.log(window.BirdComponentRegistry.chat);
  };

  // Dynamically add the messagebird-chat component when the button is clicked
  useEffect(() => {
    if (isChatVisible) {
      // Create the messagebird-chat element
      const chatElement = document.createElement("messagebird-chat");
      chatElement.setAttribute(
        "project-id",
        "6ed8ab20-f306-40c8-9ff7-572451c14c31"
      );
      chatElement.setAttribute(
        "workspace-id",
        "53912c7d-1049-4eaf-b4e1-2c982f867ba5"
      );

      // Append the chat widget to the body
      document.body.appendChild(chatElement);

      setTimeout(() => {
        const shadowRoot = chatElement.shadowRoot;

        // If shadowRoot is available, query the button inside it
        if (shadowRoot) {
          // @ts-ignore
          const button = [...shadowRoot.querySelectorAll("button")].find(
            (btn) => {
              return btn.textContent.includes("открыть виджет");
            }
          );

          button.style.backgroundColor = "#93C063";
          button.style.color = "white";
          if (button) {
            console.log("Button found inside shadow DOM:", button);

            button.click();

            // Optionally, you can also add event listeners to the button
            button.addEventListener("click", () => {
              console.log("Button clicked inside shadow DOM");
            });
          }
        }
      }, 1000); // Delay to ensure the shadow DOM is ready
    }

    // Cleanup the chat widget when it's no longer needed
    return () => {
      const chatElement = document.querySelector("messagebird-chat");
      if (chatElement) {
        document.body.removeChild(chatElement);
      }
    };
  }, [isChatVisible]);

  return (
    <button className={className} onClick={toggleChat}>
      {text}
    </button>
  );
};
